/*
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2024-02-28 10:24:21
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-06 20:36:36
 * @FilePath: \yda_web_manage\src\views\businessManage\operationLog\hooks\data.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const detailTypeEnums = {
  2: 'text',
  3: 'image',
  4: 'icon',
  5: 'list',
  8: 'authority',
  9: 'textList'
}

export const iconList = {
  ICON_1: require('@/assets/images/business/icon_1.png'),
  ICON_2: require('@/assets/images/business/icon_2.png'),

  ICON_3: require('@/assets/images/business/icon_3.png'),

  ICON_4: require('@/assets/images/business/icon_4.png'),

  ICON_5: require('@/assets/images/business/icon_5.png'),

  ICON_6: require('@/assets/images/business/icon_6.png'),

  ICON_7: require('@/assets/images/business/icon_7.png'),

  ICON_8: require('@/assets/images/business/icon_8.png'),
  ICON_9: require('@/assets/images/business/icon_9.png'),

  ICON_10: require('@/assets/images/business/icon_10.png'),

  ICON_11: require('@/assets/images/business/icon_11.png'),

  ICON_12: require('@/assets/images/business/icon_12.png')
}

export const noOldValueEnums = [23, 24, 64, 65, 66, 67, 118, 120, 121, 123]

export const noOldValueLabelCss = { 'text-align': 'left', width: 'fit-content' }

export const labelStyleType = {
  5: {
    'text-align': 'left',
    width: 'fit-content'
  }
}
